import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MortgageCalculator from "../../components/MortgageCalculator";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const SteuernImmobilienkauf = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Steuern beim Immobilienkauf in Österreich" showCalc={false} />
            <Article>
                <p>
                    Du möchtest eine Immobilie kaufen, hast aber keine Ahnung wie es dabei steuerrechtlich aussieht? In
                    diesem Artikel wird dir ein kurzer Überblick darüber gegeben, welche Steuern beim Immobilienkauf in
                    Österreich auf dich zukommen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Die Grunderwerbssteuer</h2>
                <p>
                    Sobald ein Grundstück auf eine andere Person übertragen wird, fällt in Österreich die sogenannte
                    Grunderwerbssteuer an. Normalerweise wird die Grunderwerbssteuer von der Käuferin oder dem Käufer
                    übernommen, das ist allerdings nicht gesetzlich festgelegt. Alle am Kauf beteiligten Parteien sind
                    gemeinsam dafür verantwortlich, dass die Steuer ordnungsgemäß gezahlt wird.
                </p>
                <p>
                    Nicht verwechseln: Die Grunderwerbssteuer und die Grundsteuer sind nicht dasselbe. Die Grundsteuer
                    wird von der zuständigen Gemeinde durch den Einheitswert des Grundstücks berechnet und eingefordert.
                </p>
                <h3>Wie wird sie ermessen?</h3>
                <p>
                    Die Höhe der Grunderwerbsteuer hängt davon ab, ob es sich um einen entgeltlichen oder einen
                    unentgeltlichen Erwerb handelt. Ein wichtiger Wert, den du dabei kennen solltest, ist die sogenannte
                    Bemessungsgrundlage. Zur Bemessungsgrundlage zählen alle Werte, die für die Ermittlung der
                    Steuerschuld erforderlich sind.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <h3>Die Bemessungsgrundlage</h3>
                <p>
                    Wenn es sich um eine entgeltliche Übertragung einer Immobilie handelt, gilt der Kaufpreis der
                    Immobilie (Verkehrswert) als Grundlage für die Bemessung der Grunderwerbsteuer. Erfolgt die
                    Übertragung jedoch unentgeltlich, wird der Grundstückswert als Grundlage dafür herangezogen. Dieser
                    setzt sich aus Boden- und Gebäudewert zusammen. Grundsätzlich beträgt die Grunderwerbssteuer immer
                    3,5 Prozent der Bemessungsgrundlage. Dabei gibt es jedoch eine Ausnahme: landwirtschaftlich genutzte
                    Flächen, bei welchen der einfache Einheitswert als Bemessungsgrundlage gilt.
                </p>
                <h3>Grunderwerbssteuer bei Schenkungen</h3>
                <p>
                    Eine Schenkung ist nicht unbedingt ein geschenktes Objekt. Es handelt sich dabei bloß um einen
                    sogenannten unentgeltlichen Übertrag. Wenn du eine Wohnung oder ein Grundstück günstig von einem
                    Familienmitglied kaufst, gilt auch dieser Kauf zum Teil als unentgeltlicher Übertrag. Auch bei einer
                    Schenkung fällt also eine Grunderwerbssteuer an. Im Unterschied zum entgeltlichen Übertrag kommt
                    hier jedoch ein Stufentarif zur Anwendung. Die 3,5 Prozent-Regel setzt erst bei einem Wert von über
                    400.000 &euro; ein.
                </p>
                <hr />

                <h2>Steuern beim Immobilienkauf mit Makler*in</h2>
                <p>
                    Den Überblick über alle nötigen Gebühren beim Immobilienkauf zu behalten, ist eine große
                    Herausforderung. Oft lohnt es sich deshalb, die Leistung von Immobilienmakler*innen in Anspruch zu
                    nehmen. Dafür fällt jedoch eine Maklerprovision an, die du bezahlst, sobald ein Kauf- oder
                    Mietvertrag geschlossen wird. Bei einem Hauskauf kannst du mit ca. 3 Prozent des Kaufpreises für die
                    Provision rechnen. Hinzu kommen noch 20 Prozent gesetzlich festgelegter Umsatzsteuer.
                </p>
                <p>
                    Diese Beträge werden beim privaten Immobilienkauf als Anschaffungskosten gewertet und sind damit
                    nicht von der Steuer absetzbar. Wenn du dir eine Immobilie für berufliche Zwecken anschaffen
                    möchtest, kann die Maklerprovision jedoch von der Steuer abgesetzt werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienertrags- und Immobilienerwerbssteuer</h2>
                <p>
                    Über diese beiden Begriffe musst du dir beim Immobilienkauf in Österreich glücklicherweise keine
                    Gedanken machen. Die Immobilienertragssteuer fällt erst an, wenn du selbst eine Immobilie verkaufen
                    möchtest und bezieht sich auf den Gewinn, den du bei diesem Verkauf erzielst.
                    Immobilienerwerbssteuer fällt in Österreich keine an, in anderen Ländern hingegen schon. Wenn du
                    also eine Immobilie im Ausland erwerben möchtest, musst du dich über die Gesetzeslage des jeweiligen
                    Landes informieren.
                </p>
                <hr />

                <h2>Kann man sich die Steuern beim Immobilienkauf sparen?</h2>
                <p>
                    Auch beim Immobilienkauf für private Zwecke kannst du unter bestimmten Voraussetzungen Steuern
                    sparen, wenn damit begünstigter Wohnraum geschaffen wird. Jahreseinkommen und Art des Kaufs spielen
                    dabei eine Rolle. Wenn die Einkünfte mehr als 60.000 &euro; im Jahr betragen, können keine Kosten
                    abgesetzt werden. Genauso ist es auch, wenn es sich nicht um einen Neubau, sondern um den Kauf eines
                    bereits bestehenden Objekts handelt. Wenn du einen Rohbau kaufst, kannst du keine Sonderausgaben
                    absetzen. Aber Alle Baumaßnahmen, die nach dem Kauf anfallen, können von der Steuer abgesetzt
                    werden.
                </p>
                <hr />

                <h2>Exkurs: Steuern beim Verkauf einer Immobilie</h2>
                <p>
                    Jeder, der eine Immobilie entgeltlich verkauft, muss die Immobilienertragssteuer bezahlen. Diese
                    fällt bei einem gewinnbringenden Verkauf an. Versteuert wird der Veräußerungsgewinn, sprich die
                    Differenz zwischen Erlös und Anschaffungskosten des Objekts.
                </p>
                <p>
                    <strong>Tipp:</strong> Bewahre deine Belege für Renovierungen, Sanierungen etc. unbedingt auf. Diese
                    angefallenen Arbeitskosten können von dem Gewinn abgezogen werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"steuernImmobilienkauf"}></BreadcrumbList>
            <ArticleStructuredData
                page={"steuernImmobilienkauf"}
                heading={"Steuern beim Immobilienkauf in Österreich"}
            />
        </Layout>
    );
};

export default SteuernImmobilienkauf;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.steuern-beim-immobilienkauf", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
